import React, { useEffect } from "react"
import { Header } from "./Header"
import styled, { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import { colors } from '../constants'

const GlobalStyle = createGlobalStyle`
  body {
    // background-color: ${colors.background};
    background-color: #222;
    font-family: Arial, Helvetica, sans-serif;
    color: ${colors.black};
    -webkit-font-smoothing: antialiased;
  }
`

const Container = styled.div`
  padding: 0 20px;
`

interface Props {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {

  return (
    <Container>
      <GlobalStyle />
      <Reset />
      {/* <Header /> */}
      <main>
        {children}
      </main>
    </Container>
  )
}
