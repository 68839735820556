import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/seo'
import { Layout } from '../components/Layout'
import { colors } from '../constants'
import { fontsName } from '../fonts/fontsName'


const Wrapper = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

const Content = styled.div`
  margin: 50px 0;
  font-family: ${fontsName.KnockoutHTF32JuniorCruiserwt};
  font-size: 16px;
  letter-spacing: 1.2px;
  line-height: 26px;

  a {
    color: ${colors.linkColor};
    text-decoration: none;
  }
`

const Links = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
`

const Link = styled.a`
  margin: 0 20px;
  font-size: 25px;
  color: #fff !important;
`

const About = () => {
  return (
    <Layout>
      {/* <SEO title="À Propos" /> */}
      <Wrapper>
        <Content>
          Développeur web freelance spécialisé ReactJS / React Native / TypeScript / NodeJS.
          <Links>
            <Link href="https://www.linkedin.com/in/guillaumejasmin/">
              Linkedin
            </Link>
            <Link href="https://www.malt.fr/profile/guillaumejasmin">
              Malt
            </Link>
            <Link href="https://github.com/GuillaumeJasmin">
              Github
            </Link>
          </Links>
        </Content>
      </Wrapper>
    </Layout>
  )
}

export default About
