export const fontsName = {
  InterMedium: 'Inter-Medium',
  KnockoutHTF31JuniorMiddlewt: 'Knockout-HTF31-JuniorMiddlewt',
  KnockoutHTF32JuniorCruiserwt: 'Knockout-HTF32-JuniorCruiserwt',
  KnockoutHTF34JuniorSumo: 'Knockout-HTF34-JuniorSumo',
  KnockoutHTF50Welterweight: 'Knockout-HTF50-Welterweight',
  KnockoutHTF52Cruiserweight: 'Knockout-HTF52-Cruiserweight',
  KnockoutHTF54Sumo: 'Knockout-HTF54-Sumo',
  KnockoutHTF71FullMiddlewt: 'Knockout-HTF71-FullMiddlewt',
  KnockoutHTF93UltmtHeviwt: 'Knockout-HTF93-UltmtHeviwt',
}

